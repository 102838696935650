import React, { useState } from "react";
import Select from "react-select";
import AWS from "aws-sdk";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [service, setService] = useState(null);
  const [phone, setPhone] = useState("");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);

  AWS.config.update({
    accessKeyId: "AKIA2UTXHYOVFGKLBVLV",
    secretAccessKey: "5epbezWdr86sUFzQHiQHZhc3Rm1/BycBCIHqG0Y0",
    region: "us-east-1", // Change to your desired AWS region
  });

  const ses = new AWS.SES({ apiVersion: "2010-12-01" });

  console.log(service);
  const params = {
    Destination: {
      ToAddresses: ["chavez.king@quoinmanagement.com"],
    },
    Message: {
      Body: {
        Text: {
          Data: `Name: ${name}, \n Email: ${email}, \n message: ${message}, \n service: ${service?.value}, \n phone: ${phone}, \n \n Thank You`,
        },
      },
      Subject: {
        Data: `New contact from ${name}`,
      },
    },
    Source: `chavez.king@quoinmanagement.com`,
  };

  const options = [
    { label: "sales", value: "sales" },
    { label: "Property Management", value: "Property Management" },
    { label: "residential Management", value: "residential Management" },
    { label: "Legal", value: "Legal" },
    { label: "Commercial Management", value: "Commercial Management" },
    { label: "Comliance Management", value: "Comliance Management" },
  ];

  const eMailer = (e) => {
    e.preventDefault();

    {console.log(
        service?.value,
        email,
        message,
        phone,
        sent,
        name
      )}
      
    ses.sendEmail(params, (err, data) => {
      if (err) {
        alert("OOPS! Something went wrong!!");
        setError("OOPS! Something went wrong!!");
        console.error("Error sending email:", err);
      } else {
        setSent(true);
        console.log("Email sent successfully:", data);
      }
    });
  };

  return (
    <div className="ltn__contact-message-area mb-120 mb--100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Contact Us</h4>
              <form
                id="contact-form"
                onSubmit={eMailer}
                name="contactFormEmail"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item">
                      <Select
                        options={options}
                        value={service}
                        onChange={(values) => setService(values)}
                        isMulti={false}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Enter phone number"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="message"
                    placeholder="Enter message"
                    defaultValue={""}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    required
                  />
                </div>
                <p>
                  {/* <label className="input-info-save mb-0">
                                        <input type="checkbox" name="agree" /> Save my name,
                                        email, and website in this browser for the next time I
                                        comment.
                                    </label> */}
                </p>
                <div className="btn-wrapper mt-0">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                  >
                    submit
                  </button>
                </div>
                <p className="mb-0 mt-20" style={{ color: "green" }}>
                  {sent ? "Message Sent Successfully" : null}
                </p>
                <p className="mb-0 mt-20" style={{ color: "red" }}>
                  {error ? error : null}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
