import React, { Component } from "react";
import "../../assets/sass/emergency-response.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import Navbar from "../global-components/navbar-v4";
import PageHeader from "../global-components/page-header";

import CallToActionV1 from "../section-components/call-to-action-v1";
import SponsorV2 from "../section-components/sponsor-v2";
import Footer from "../global-components/footer";

class EmergencyResponse extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div>
        <div className="emergency-details-page ltn__page-details-area ltn__emergency-details-page-area mb-105">
          <Navbar />
          <div className="emergency-details-page__emergency-details-page-header">
            <h1 className="emergency-details-page__project-title">
              Emergency Response
            </h1>
          </div>

          {/* <PageHeader headertitle="Portfolio 1" /> */}

          <div className="emergency-details-page__container container w-100">
            <div className="row">
              <p>
                {" "}
                <span className="ltn__first-letter">Q</span>uion property
                management recognizes the importance of emergency preparedness
                and response in ensuring the safety and well-being of their
                clients and tenants. We provide various forms of emergency
                response help to our clients to ensure that they are prepared
                for any unforeseen events.
              </p>
              <p>
                {" "}
                Here are some ways Quion Management provides emergency
                response help:
              </p>
              <p>
              Ongoing Review and Improvement: Regularly reviewing and updating 
              emergency response plans based on lessons learned from past incidents 
              and changing circumstances to enhance overall preparedness.<br/>

              Documentation and Reporting: Keeping detailed records of emergency incidents, 
              actions taken, and outcomes, which can be crucial for insurance claims and 
              future emergency response improvements.
              </p>
              <p>
              Tenant Assistance: Providing guidance and support to tenants during emergencies, 
              offering clear instructions, reassurance, and helping them adhere to safety measures.
              <br/>
              Vendor Coordination: Maintaining relationships with reliable vendors for repairs, 
                cleanup, and restoration services, ensuring a swift response and efficient 
                resolution of property damage.</p>
            </div>
            <div className="row mt-4 mb-4 reverse-columns">
              <div className="col-lg-6">
                <img
                  src={publicUrl + "assets/img/service/emergency-1.jpg"}
                  alt="image"
                />
              </div>
              <p className="col-lg-6">
                <h1> 24/7 Emergency Hotline </h1>
                Quion property management provides a 24/7 emergency hotline that
                tenants can call in case of emergencies. This hotline is staffed
                by trained professionals who can provide immediate assistance
                and dispatch emergency services if necessary.
              </p>
            </div>
            <div className="row mt-3 mb-5">
              <p className="col-lg-6 ">
                <h1> Emergency Preparedness Planning </h1>
                Quion property management can assist clients in developing
                emergency preparedness plans that outline the procedures to be
                followed in the event of an emergency. This includes evacuation
                plans, emergency contacts, and procedures for communication and
                coordination with emergency responders.
              </p>
              <div className="col-lg-6">
                <img
                  src={publicUrl + "assets/img/service/emergency-2.jpg"}
                  alt="image"
                />
              </div>
            </div>
            <div className="row mt-4 mb-4 reverse-columns">
              <div className="col-lg-6">
                <img
                  src={publicUrl + "assets/img/service/emergency-3.jpg"}
                  alt="image"
                />
              </div>
              <p className="col-lg-6">
                <h1> Property Inspection</h1>
                Property Inspection: Quoin management can conduct regular
                property inspections to ensure that the property is in
                compliance with local and state laws. This can include ensuring
                that the property is safe for tenants and that it meets health
                and safety standards.
              </p>
            </div>
          </div>
          <div className="row mt-4 mb-4"></div>
          <div className="col-lg-6"></div>
          <SponsorV2 />
          <CallToActionV1 />
          <Footer />
        </div>
      </div>
    );
  }
}

export default EmergencyResponse;
